<template>
  <div v-if="isShowGoogleSign || isLoadedGoogleScript" v-loading="googleSignLoading" class="elv-google-sign-container">
    <div id="elv-google-sign" ref="googleSignContainerRef" />
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import userApi from '@/api/UserApi'
import { isEmpty } from 'lodash-es'
import { ElMessage } from 'element-plus'
import { useCookies } from 'vue3-cookies'
import { isMobile as isMobileFn } from '@/lib/utils'
import { useGlobalStore } from '@/stores/modules/global'
import { useUserGlobalStore } from '@/stores/modules/global/user'

const router = useRouter()
const { cookies } = useCookies()
const { t, locale } = useI18n()
const globalStore = useGlobalStore()
const userGlobalStore = useUserGlobalStore()

const emit = defineEmits(['checkLoading', 'onCompleteRegister', 'onStartTwoFactorAuthVerify'])

const googleSignContainerRef = useTemplateRef('googleSignContainerRef')

const isMobile = computed(() => {
  return isMobileFn()
})

const isShowGoogleSign = computed(() => {
  return !isEmpty(window?.google?.accounts)
})

const googleSignWidth = ref(315)
const googleSignLoading = ref(false)
const isLoadedGoogleScript = ref(false)
const googleConfig = computed(() => {
  return {
    ux_mode: 'redirect',
    theme: 'outline',
    size: 'large',
    type: 'standard',
    shape: 'rectangular',
    logo_alignment: 'center',
    height: 45,
    width: googleSignWidth.value,
    auto_select: false,
    login_uri: `${import.meta.env.VITE_GOOGLE_REDIRECT_URL}`,
    text: 'continue_with',
    locale: locale.value
  }
})

/**
 * @description: 处理Google凭证响应
 * @param {*} response
 */
const handleCredentialResponse = async (response: any) => {
  try {
    const params = {
      googleToken: response.credential
    }
    googleSignLoading.value = true
    const { data } = await userApi.googleLogin(params)
    // 两步验证
    if (data.twoFactorAuth) {
      emit('onStartTwoFactorAuthVerify', data.token)
    } else {
      cookies.set('elv-app-token', data.token, '7d', '/', import.meta.env.VITE_APP_COOKIES_DOMAIN, true)
      if (cookies.get('elv-app-version') === 'v2' || (cookies.get('elv-app-version') === null && data.isOld)) {
        window.open(`${import.meta.env.VITE_APP_VERSION_URL}`, '_self')
        return
      }
      await userGlobalStore.userInit()
      // 如果用户没有名字，说明是新用户，需要完善信息
      if (!userGlobalStore.user?.name) {
        emit('onCompleteRegister')
      } else {
        await globalStore.fetchProjectList()
        ElMessage.success(t('message.signInSuccess'))
        router.push('/')
        userGlobalStore.closeLogin()
      }
    }
  } catch (error: any) {
    console.log(error)
    ElMessage.error(error?.message)
  } finally {
    googleSignLoading.value = false
  }
}

/**
 * @description: 初始化Google登录
 */
const initializeGoogleSign = () => {
  nextTick(() => {
    if (window?.google?.accounts && googleSignContainerRef.value) {
      window.google.accounts.id.initialize({
        client_id: import.meta.env.VITE_GOOGLE_KEY,
        callback: handleCredentialResponse
      })
      const screenWidth = document.body.clientWidth
      googleSignWidth.value = isMobile.value || screenWidth < 768 ? 290 : 315
      window.google.accounts.id.renderButton(googleSignContainerRef.value, googleConfig.value)
      window.addEventListener('resize', () => {
        const { clientWidth } = document.body
        let buttonWidth = 315
        if (isMobile.value || clientWidth < 768) {
          buttonWidth = 290
        } else {
          buttonWidth = 315
        }
        if (buttonWidth !== googleSignWidth.value) {
          googleSignWidth.value = buttonWidth
          const googleSignInContainer: any = document.getElementById('elv-google-sign')
          window.google.accounts.id.renderButton(googleSignInContainer, googleConfig.value)
        }
      })
      googleSignLoading.value = false
    }
  })
}

/**
 * @description: 加载完成Google登录脚本
 */
const onReadyGoogleScript = () => {
  isLoadedGoogleScript.value = true
  initializeGoogleSign()
}

onMounted(() => {
  nextTick(() => {
    if (isShowGoogleSign.value) {
      initializeGoogleSign()
    }
    const script = document.querySelector('script[src="https://accounts.google.com/gsi/client"]')
    if (script) {
      script.addEventListener('load', onReadyGoogleScript)
    }
  })
})

onBeforeUnmount(() => {
  googleSignLoading.value = false
  const script = document.querySelector('script[src="https://accounts.google.com/gsi/client"]')
  if (script) {
    script.removeEventListener('load', onReadyGoogleScript)
  }
})
</script>

<style lang="scss">
.elv-google-sign-container {
  .el-loading-spinner {
    height: 45px;

    svg {
      height: 40px;
      width: 40px;
    }
  }
}

#elv-google-sign {
  margin-bottom: 10px;
  height: 45px;
  min-width: 290px;
  max-width: 315px;
}
</style>
